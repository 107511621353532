import React from 'react'
import styles from './projects.module.css'
import Project from './project'

import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'

const Projects = ({ projects = [] }) => (
  <ul className={styles.wrapper}>
    {
      projects.edges.map(
        ({ node }) =>
          <Project
            key={node.id}
            title={node.title}
            href={node.href}
            image={!!node.image && imageUrlFor(buildImageObj(node.image)).url()}
            video={!!node.video && node.video.url}
            _rawDescription={node._rawDescription}
          />
      )
    }
  </ul>
)

export default Projects
