import {Link} from 'gatsby'
import React, { useContext } from 'react'

import BlockText from '../../block-text'
import Video from '../../video/video'

import styles from './project.module.css'
import {paragraph, responsiveTitle2} from '../../typography.module.css'
import {cn} from '../../../lib/helpers'

import {AppContext} from '../../../contexts/app-context'

const Project = ({
  title = '',
  href = '',
  image = '',
  video = '',
  _rawDescription,
}) => {
  const {showGlow} = useContext(AppContext)
  const titleClassName = showGlow ? cn(responsiveTitle2, 'glow') : responsiveTitle2
  const imgClassName = showGlow ? cn(styles.image, 'aura') : styles.image
  return (
    <li className={styles.wrapper}>
      {
        !!href
          ? <h3 className={titleClassName}>
              <Link className={styles.link} to={href} target='_blank'>
                {title}
              </Link>
            </h3>
          : <h3 className={titleClassName}>{title}</h3>
      }
      {
        !!image &&
          <img className={imgClassName} src={image} alt={image.alt} />
      }
      { !!video && <Video src={video} /> }
      {
        !!_rawDescription &&
          <div className={cn('rich-text', 'break-words', paragraph, styles.description)}>
            <BlockText blocks={_rawDescription} />
          </div>
      }
    </li>
  )
}

export default Project