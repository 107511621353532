import React, {useContext} from 'react'

import styles from './video.module.css'
import {cn} from '../../lib/helpers'

import {AppContext} from '../../contexts/app-context'

const Video = ({src = '', title, description }) => {
  const {showGlow} = useContext(AppContext)
  const iframeClassName = showGlow ? cn(styles.iframe, 'aura') : styles.iframe
  return (
    <div className={styles.wrapper}>
      { !!title && title }
      <iframe
        className={iframeClassName}
        src={`${src}?modestbranding=1&rel=0`}
        title="YouTubevideo player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen="allowfullscreen"
        mozallowfullscreen="mozallowfullscreen"
        msallowfullscreen="msallowfullscreen"
        oallowfullscreen="oallowfullscreen"
        webkitallowfullscreen="webkitallowfullscreen">
      </iframe>
      { !!description && description }
    </div>
  )
}

export default Video
