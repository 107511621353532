import {graphql} from 'gatsby'
import React from 'react'

import Layout from '../containers/layout'
import Projects from '../components/projects'

export const query = graphql`
  query ProjectsPageQuery {
    projects: allSanityProject(
      sort: {fields: [publishedAt], order: DESC}
    ) {
      edges {
        node {
          id
          title
          _rawDescription
          image {
            asset {
              _id
            }
          }
          video {
            url
          }
          href
        }
      }
    }
  }
`

const ProjectsPage = ({ data: { projects } }) => (
  <Layout>
    <Projects projects={projects} />
  </Layout>
)

export default ProjectsPage
